import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const FuturePage = () => (
  <Layout>
    <SEO title="About" />

    <p>Welcome to future courses</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default FuturePage
